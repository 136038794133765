<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <sigmind-logo />
          <h2 class="brand-text text-primary ml-1">
            Sig-Mind
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Bem vindo ao Sig-Mind! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Por gentileza realize login para iniciar o sistema e visualizar sua
          conta!
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder=""
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Senha"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder=""
                    autocomplete="on"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <div class="d-flex justify-content-between">
                <label for="password">Senha</label>
                <b-link :to="{ name: 'forgot-password' }">
                  <small>Esqueceu a senha?</small>
                </b-link>
              </div>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Lembre-se de mim!
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="login()"
            >
              Efetuar login
            </b-button>
            <div
              v-if="carregando"
              class="text-center"
            >
              <br>
              <b-spinner class="mr-1 spinner-border text-primary" />
            </div>
          </b-form>
        </validation-observer>

        <!-- A funcionalidade abaixo será reativada com as devidas alterações caso necessário -->
        <!-- <b-card-text class="text-center mt-2">
          <span>É a sua primeira vez na plataforma?</span>
          <br>
          <b-link :to="{name:'register'}">
            <span>Sim! Cadastrar nova conta</span>
          </b-link>
        </b-card-text> -->
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate"
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BSpinner
} from "bootstrap-vue"
import SigmindLogo from "@core/layouts/components/Logo.vue"
import { required, email } from "@validations"
import { togglePasswordVisibility } from "@core/mixins/ui/forms"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import useJwt from "@/auth/jwt/useJwt"
import {
  getHomeRouteForLoggedInUser,
  getUserData,
  isUserLoggedIn
} from "@/auth/utils"

let teste = ""
teste = "0"
// console.log(teste)

export default {
  components: {
    // BSV,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    SigmindLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userData: getUserData(),
      carregando: false,
      userEmail: "",
      password: "",
      status: "",
      // validation rules
      required,
      email
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon"
    },
    perfil: {
      get() {
        return this.$store.state.user.idDoPerfil
      },
      set(valor) {
        this.$store.commit("setPerfil", valor)
      }
    }
  },
  mounted() {
    if (!!localStorage.getItem("redirecionado")) {
      this.usuarioRedirecionado()
    }
  },
  methods: {
    usuarioRedirecionado() {
      this.MensagemToastLoginExpiradoUsuario("danger", "top-center")
      if (!!localStorage.getItem("Rede")) {
        localStorage.removeItem("Rede")
      } else {
        localStorage.removeItem("redirecionado")
      }
    },
    CheckUserLogin() {
      if (isUserLoggedIn()) {
        // console.log('Login: Usuário está logado.')
        this.$router.replace(
          getHomeRouteForLoggedInUser(this.userData.idDoPerfil)
        )
      } else {
        this.$router.replace("/login").then(() => location.reload())
      }
    },
    async login() {
      this.carregando = true
      const resp = await useJwt
        .login({
          emailDoUsuario: this.userEmail,
          senhaDoUsuario: this.password
        })
        .then(response => response)
        .catch(response => response)

      setTimeout(() => {
        this.carregando = false
        try {
          const retorno = resp.data
          const usuarioLogado = retorno.dados.usuarioLogado

          if (retorno.sucesso) {
            useJwt.setToken(usuarioLogado.token)
            useJwt.setRefreshToken(usuarioLogado.refreshToken)
            localStorage.removeItem("userData")
            localStorage.removeItem("redirecionado")
            localStorage.setItem("userData", JSON.stringify(usuarioLogado))
            this.userData = getUserData()
            // console.log('Setando idDoPerfil: ', usuarioLogado.idDoPerfil)
            this.perfil = usuarioLogado.idDoPerfil

            // const usuarioLocalStorage = getUserData()
            // // const usuarioLocalStorage = JSON.parse(localStorage.getItem('userData'))
            // console.log('--- localStorage-> userData completo: ---')
            // console.log(usuarioLocalStorage)

            if (usuarioLogado.statusDoEmail === 0) {
              this.$router.push({ name: "new-password" }).then(() => {
                this.MensagemToastNovaSenhaUsuario("warning", "top-center")
              })
              return
            }
            // Depois tem que verificar o perfil do usuário e redirecionar conforme padrão que será montada as páginas
            this.$router
              .push({
                name: getHomeRouteForLoggedInUser(
                  this.$store.state.user.idDoPerfil
                )
              })
              .then(() => {
                // location.reload()
                this.MensagemToastLoginUsuario("success", "top-center")
              })

            return
          }
        } catch (error) {
          localStorage.removeItem("userData")
          // console.log(error)
          this.MensagemToastLoginIncorretoUsuario("danger", "top-center")
        }
      }, 7000)
    },
    MensagemToastNovaSenhaUsuario(variant, position) {
      const nomeDoUsuario = getUserData().nomeDoUsuario
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Bem vindo" + nomeDoUsuario + "!",
            icon: "CoffeeIcon",
            variant,
            text:
              "Sua senha foi alterada recentemente! Você precisa ajustá-la antes de prosseguir."
          }
        },
        {
          position
        }
      )
    },
    MensagemToastLoginUsuario(variant, position) {
      const nomeDoUsuario = getUserData().nomeDoUsuario
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Bem vindo " + nomeDoUsuario + "!",
            icon: "CoffeeIcon",
            variant,
            text:
              "Você já pode começar a explorar o sistema, sinta-se a vontade para nos chamar!"
          }
        },
        {
          position
        }
      )
    },
    MensagemToastLoginIncorretoUsuario(variant, position) {
      const nomeDoUsuario = getUserData().nomeDoUsuario
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Os dados de login estão corretos?",
            icon: "AlertTriangleIcon",
            variant,
            text:
              "Por gentileza verifique os dados de login, caso não se lembre utilize o link de esquecimento de senha."
          }
        },
        {
          position
        }
      )
    },
    MensagemToastLoginExpiradoUsuario(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "O login expirou.",
            icon: "XOctagonIcon",
            variant,
            text:
              "Você ficou ocioso por muito tempo e por este motivo foi redirecionado para realizar novo login."
          }
        },
        {
          position
        }
      )
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
